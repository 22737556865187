import Client from './Clients/AxiosClient';
const resource = '/bet';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    play(data) {
        return Client.post(`${resource}/place`, data);
    },
    rewards() {
        return Client.post(`${resource}/rewards`);
    },
    vault(data) {
        return Client.post(`${resource}/vault`, data);
    }
}