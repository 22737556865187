import Client from './Clients/AxiosClient';
const resource = '/user';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getUserData() {
        return Client.get(`${resource}/data`);
    },
    authorize(data) {
        return Client.post(`${resource}/authorize`, data);
    }    
}