import NonceRepository from "./NonceRepository";
import UserRepository from "./UserRepository";
import BetRepository from "./BetRepository";

const repositories = {
    'nonce' : NonceRepository,
    'user' : UserRepository,
    'bet' : BetRepository
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    get: name => repositories[name]
};