/* eslint-disable react-hooks/exhaustive-deps */
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey, Connection, clusterApiUrl } from "@solana/web3.js";
import { useEffect, useState } from 'react';
import Repository from '../repositories/RepositoryFactory'
import Board from './Board';
import Footer from "./Footer";
import Leaderboard from "./Leaderboard";
import Nav from './Nav'
import Welcome from './Welcome';
import config from "../json/config.json";

require('@solana/wallet-adapter-react-ui/styles.css');

function Auth(props) {
    const { publicKey } = useWallet();
    const NonceRepository = Repository.get('nonce');
    const UserRepository = Repository.get('user');
    const BetRepository = Repository.get('bet');
    const getProvider = () => {
        if ('phantom' in window) {
            const provider = window.phantom?.solana;
            if (provider?.isPhantom) {
                return provider;
            }
        }
    }
    const [walletSigned, setSigned] = useState(false);
    const [rewardsPaid, setRewardsPaid] = useState(0.0000);
    const [credits, setCredits] = useState(0.0000);
    const [creditUpdated, setCreditUpdated] = useState(false);
    const [boardNumber, setBoardNumber] = useState(0);
    const setBoard = (num)=>{
        console.log(num)
        setBoardNumber(num);
    }
    const toggleCreditUpdated = ()=>{
        setCreditUpdated(!creditUpdated);
    }

    async function signMessage(nonce) {
        const provider = getProvider()
        const message = `Authorize your wallet. nonce:${nonce}`;
        const encodedMessage = new TextEncoder().encode(message);
        const signedMessage = await provider.signMessage(encodedMessage, "utf8");
        authWallet(message, signedMessage.signature, nonce);
    }
    async function authWallet(message, signature, nonce) {
        const data = {
            wallet: publicKey,
            message: message,
            signature: Object.values(signature),
            nonce: nonce
        };
        UserRepository.authorize(data)
            .then(
                response => {
                    if (response.status === 200) {
                        sessionStorage.setItem("user_wallet", publicKey);
                        sessionStorage.setItem("signature", Object.values(signature));
                        sessionStorage.setItem("nonce", nonce);
                        setSigned(true);
                        toggleCreditUpdated()
                    }
                }
            )
    }

    useEffect(() => {
        try {
            if (publicKey) {
                const data = {
                    wallet: publicKey.toString()
                };
                NonceRepository.create(data)
                    .then(
                        response => {
                            signMessage(response.data.nonce);
                        }
                    );
                console.log('public key', publicKey.toString());
            }
        } catch(e) {
            console.log(e);
        }
    }, [publicKey]);
    useEffect(() => {
        BetRepository.rewards().then(
                response => {
                    if (response.status === 200) {
                        setRewardsPaid(response.data.totalRewardsPaid ?? 0);
                    }
                }
            )
    });
    useEffect(()=>{
        if(publicKey){
            const NETWORK = clusterApiUrl(config.NETWORK);
            const connection = new Connection(NETWORK);
            const data = {
                wallet: publicKey.toString(),
                signature: sessionStorage.getItem("signature")
            };
            BetRepository.vault(data).then(
                response => {
                    if (response.status === 200) {
                        const vault = response.data.vault;
                        sessionStorage.setItem("vault", vault);
                        const vaultPubkey = new PublicKey(vault);
                        connection.getBalance(vaultPubkey).then((balance)=>setCredits((balance / 10 ** 9).toFixed(3)))
                    }
                }
            )
        }
    }, [creditUpdated])
    return (
        <>
            <Nav board={boardNumber} setBoardNumber={setBoard}/>
            {walletSigned ? ( boardNumber === 0 ? (
                    <Board setRewardsPaid={setRewardsPaid} balance={credits} toggleCreditUpdated={toggleCreditUpdated}/>
                ) : (
                    <Leaderboard rewardsPaid={rewardsPaid}/>
                )) : (
                    <Welcome />
                )
            }
            <Footer />
        </>
    )
}

export default Auth;