/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";

require("@solana/wallet-adapter-react-ui/styles.css");

function Leaderboard(props) {
  return (
    <div className="board leaderboard">
      <div className="total-rewards">
        <h2>Total Rewards Paid:</h2>
         <br></br>
         <p>{Math.round(props.rewardsPaid * 100) / 100} SOL</p>
      </div>
    </div>
  );
}

export default Leaderboard;
