import Navbar from 'react-bootstrap/Navbar';

function Footer(props) {
    return (
        <div className="fixed-bottom">
            <Navbar bg="dark" variant="dark">
                <div className="m-auto">
                    <Navbar.Brand href="https://discord.com/" target="blank">
                        <img
                        alt=""
                        src="/discord.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top discord"
                        />{' '}
                        {/* Discord */}
                    </Navbar.Brand>
                    <Navbar.Brand href="https://twitter.com/" target="blank">
                        <img
                        alt=""
                        src="/twitter.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        />{' '}
                        {/* Twitter */}
                    </Navbar.Brand>
                    <Navbar.Brand href="https://github.com/" target="blank">
                        <img
                        alt=""
                        src="/github.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        />{' '}
                        {/* Github */}
                    </Navbar.Brand>
                </div>
            </Navbar>
        </div>
    )
}

export default Footer;