import Auth from './components/Auth';
import WalletAdapter from './components/WalletAdapter';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <WalletAdapter>
        <Auth />
      </WalletAdapter>
    </div>
  );
}

export default App;
