import back from '../assets/img/back.gif';

function Welcome(props) {
    
    return (
        <div className='welcome'>
            {/* adfa
            <img src={back} alt="background" className='w-100 my-auto'/> */}
        </div>
    )
}

export default Welcome;