/* eslint-disable react-hooks/exhaustive-deps */
import { useWallet } from "@solana/wallet-adapter-react";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import Repository from "../repositories/RepositoryFactory";
import config from "../json/config.json";
import {
  clusterApiUrl,
  Connection,
  Transaction,
  SystemProgram,
  LAMPORTS_PER_SOL,
  PublicKey,
} from "@solana/web3.js";
import {
  Button,
  ProgressBar,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import VideoPlay from "./VideoPlay";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSound from "use-sound";
import selectAreaAudio from "../assets/audio/Cursor Click SFX - Click A.mp3";
import playAudio from "../assets/audio/Place Bet SFX - Kaching.mp3";

require("@solana/wallet-adapter-react-ui/styles.css");

const initStatus = [
  { chance: "0.135", value: "", selected: false },
  { chance: "0.215", value: "-3", selected: false },
  { chance: "0.47", value: "-2.7", selected: false },
  { chance: "0.97", value: "-2.4", selected: false },
  { chance: "1.81", value: "-2.1", selected: false },
  { chance: "3.07", value: "-1.8", selected: false },
  { chance: "4.83", value: "-1.5", selected: false },
  { chance: "6.89", value: "-1.2", selected: false },
  { chance: "9.02", value: "-0.9", selected: false },
  { chance: "10.79", value: "-0.6", selected: false },
  { chance: "11.8", value: "-0.3", selected: false },
  { chance: "11.8", value: "0", selected: false },
  { chance: "10.79", value: "0.3", selected: false },
  { chance: "9.02", value: "0.6", selected: false },
  { chance: "6.89", value: "0.9", selected: false },
  { chance: "4.83", value: "1.2", selected: false },
  { chance: "3.07", value: "1.5", selected: false },
  { chance: "1.81", value: "1.8", selected: false },
  { chance: "0.97", value: "2.1", selected: false },
  { chance: "0.47", value: "2.4", selected: false },
  { chance: "0.215", value: "2.7", selected: false },
  { chance: "0.135", value: "3", selected: false },
];

function Board(props) {
  const [isDepositing, setDepositing] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [status, setStatus] = useState(initStatus);
  const [percentage, setPercentage] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0.2);
  const [payout, setPayout] = useState(0);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [roundEnded, setRoundEnded] = useState(false);
  const [updateRewards, setUpdateRewards] = useState(false);
  const [betAmount, setBetAmount] = useState(0.05);
  const [land, setLand] = useState();
  const [reward, setReward] = useState();
  const { publicKey } = useWallet();
  const [selectAreaAudioPlay] = useSound(selectAreaAudio);
  const [playAudioPlay] = useSound(playAudio);
  const BetRepository = Repository.get("bet");
  const getProvider = () => {
    if ("phantom" in window) {
      const provider = window.phantom?.solana;
      if (provider?.isPhantom) {
        return provider;
      }
    }
  };
  const setBarState = (index) => {
    setStatus(
      status.map((item, i) =>
        i === index ? { ...item, selected: !item.selected } : item
      )
    );
    selectAreaAudioPlay();
  };

  const clear = () => {
    setStatus(
      status.map((item, i) => (i < 22 ? { ...item, selected: false } : item))
    );
    for (let i = 1; i <= 4; i++) {
      let bet = document.getElementById(`bet-${i}`);
      bet.checked = false;
    }
    setLeft(false);
    setRight(false);
    setBetAmount(0);
  };

  const chooseBet = (num) => {
    setBetAmount(num);
    let p = 0;
    status.map((item, i) => {
      if (item.selected) {
        p = p + parseFloat(item.chance);
      }
    });
    if (p !== 0) setPayout(((num * 0.9 * 100) / p).toFixed(2));
    if (p === 0) setPayout(0);
  };

  const deposit = async () => {
    let vault = sessionStorage.getItem("vault");
    if (!vault) {
      toast.warn("Authorize wallet first!");
      return;
    }
    const vaultPubkey = new PublicKey(vault);
    const NETWORK = clusterApiUrl(config.NETWORK);
    const connection = new Connection(NETWORK);
    const provider = getProvider();

    setDepositing(true);
    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: vaultPubkey,
        lamports: LAMPORTS_PER_SOL * depositAmount,
      })
    );
    transaction.feePayer = publicKey;
    transaction.recentBlockhash = (
      await connection.getLatestBlockhash()
    ).blockhash;
    const signedTransaction = await provider.signTransaction(transaction);
    const sig = await connection.sendRawTransaction(
      signedTransaction.serialize()
    );
    await connection.confirmTransaction(sig, "finalized");
    props.toggleCreditUpdated();
    setDepositing(false);
  };

  async function play() {
    setPlaying(true);
    playAudioPlay();
    let slots = [];

    status.map((item, i) => {
      if (item.selected) slots.push(i);
    });

    if (betAmount === 0 || slots.length < 1) {
      toast.error("Please select amount and area!");
      return;
    }

    const data = {
      wallet: publicKey,
      amount: betAmount,
      slots,
      nonce: sessionStorage.getItem("nonce") || "",
    };

    console.log("playing...");
    setLand();
    setRoundEnded(false);
    setOpen(true);
    BetRepository.play(data).then((response) => {
      if (response.status === 200) {
        setPlaying(false);
        console.log("played!");
        setLand(response.data.land);
        setReward(response.data.reward.toFixed(5));
      } else {
        toast.error("Check your credit!");
      }
    });
    setTimeout(() => {
      props.toggleCreditUpdated();
    }, 21000);
  }

  useEffect(() => {
    let p = 0;
    status.map((item, i) => {
      if (item.selected) {
        p = p + parseFloat(item.chance);
      }
    });
    setPercentage(p.toFixed(3));
    if (p !== 0) setPayout(((betAmount * 0.9 * 100) / p).toFixed(2));
    if (p === 0) setPayout(0);
  }, [status]);

  useEffect(() => {
    setStatus(
      status.map((item, i) => (i < 11 ? { ...item, selected: left } : item))
    );
  }, [left]);

  useEffect(() => {
    setStatus(
      status.map((item, i) => (i > 10 ? { ...item, selected: right } : item))
    );
  }, [right]);

  useEffect(() => {
    BetRepository.rewards().then((response) => {
      if (response.status === 200) {
        props.setRewardsPaid(response.data.totalRewardsPaid.toFixed(2) ?? 0);
      }
    });
  }, [updateRewards]);

  return (
    <div className="board">
      <ToastContainer />
      <VideoPlay
        isOpen={isOpen}
        isPlaying={isPlaying}
        setOpen={setOpen}
        land={land}
        reward={reward}
        roundEnded={roundEnded}
        setRoundEnded={setRoundEnded}
      />
      {/* <ModalVideo channel='custom' ratio='1:1' autoplay isOpen={isOpen} url={collum8} onClose={() => setOpen(false)} /> */}
      <div className="px-5 pt-5">
        <div className="bet-detail bg-dark p-3 d-flex justify-content-center rounded-4">
          <div className="mx-5">
            Credits: <b>{props.balance} SOL</b>
          </div>
          <div className="mx-5">
            Bet Amount: <b>{betAmount}</b>
          </div>
          <div className="mx-5">
            Cumulative Percentage: <b>{percentage}</b>
          </div>
          <div className="mx-5">
            Potential Payout: <b>{payout} SOL</b>
          </div>
        </div>
      </div>
      <div className="p-5">
        <div className="bg-dark bet-section p-5 rounded-4">
          <div data-tip data-for="bet-amount">
            <div className="select-bet-txt">Bet amount</div>
            <div className="select-bet">
              <Form.Check
                inline
                label="0.05 SOL"
                name="group1"
                type="radio"
                id="bet-1"
                value={0.05}
                defaultChecked={true}
                onChange={() => chooseBet(0.05)}
              />
              <Form.Check
                inline
                label="0.1 SOL"
                name="group1"
                type="radio"
                id="bet-2"
                value={0.1}
                onChange={() => chooseBet(0.1)}
              />
              <Form.Check
                inline
                label="0.25 SOL"
                name="group1"
                type="radio"
                id="bet-3"
                value={0.25}
                onChange={() => chooseBet(0.25)}
              />
              <Form.Check
                inline
                label="0.5 SOL"
                name="group1"
                type="radio"
                id="bet-4"
                value={0.5}
                onChange={() => chooseBet(0.5)}
              />
            </div>
          </div>
          {/* <ReactTooltip
            id='bet-amount'
            type="light"
            place="right"
            delayShow={20}
            afterShow={() => { setTimeout(ReactTooltip.hide, 2000) }}
          >
            Select a bet amount
          </ReactTooltip> */}

          <div className="choose-side">
            <Form.Check
              inline
              type="checkbox"
              id="left"
              label="Choose left side"
              checked={left}
              onChange={() => setLeft(!left)}
            />
            <Form.Check
              inline
              type="checkbox"
              id="right"
              label="Choose right side"
              checked={right}
              onChange={() => setRight(!right)}
            />
          </div>
          <div>
            <button onClick={() => clear()} className="btn-custom">
              Clear
            </button>
          </div>
          <div className="d-flex p-3 area" data-tip data-for="bet-items">
            {status.map((item, index) => (
              <div className="box-item" key={index}>
                <ProgressBar
                  now={100}
                  label={item.chance + "%"}
                  animated={item.selected}
                  className="bar"
                  title={item.chance}
                  onClick={() => setBarState(index)}
                />
                {/* {item.value.length > 2 && (
                  <span className="span-value">{item.value}</span>
                )}
                {item.value.length === 2 && (
                  <span className="span-value">&nbsp;{item.value}</span>
                )}
                {item.value.length === 1 && (
                  <span className="span-value">&nbsp;&nbsp;{item.value}</span>
                )} */}
              </div>
            ))}
          </div>
          {/* <ReactTooltip
            id='bet-items'
            type="light"
            place="right"
            delayShow={20}
            afterShow={() => { setTimeout(ReactTooltip.hide, 2000) }}
          >
            Select items to bet
          </ReactTooltip> */}
        </div>
      </div>
      <div className="bg-dark rounded-4 m-5 ">
        <div className="move">
          <input
            className="rounded-2 deposit-input"
            type="number"
            min={0.1}
            defaultValue={depositAmount}
            onChange={(e) => setDepositAmount(e.target.value)}
          />
          <Button
            variant="outline-primary"
            className="m-4 px-5 deposit-button"
            onClick={deposit}
          >
            {isDepositing
              ? "Depositing.."
              : "Deposit " + depositAmount + " SOL"}
          </Button>
          <Button
            variant="outline-primary"
            onClick={play}
            className="m-4 px-5 play-button"
          >
            Bet & Play
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Board;
