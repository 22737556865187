import { useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import "./VideoPlay.css";
import collum1 from "../assets/video/1.mp4";
import collum2 from "../assets/video/2.mp4";
import collum3 from "../assets/video/3.mp4";
import collum4 from "../assets/video/4.mp4";
import collum5 from "../assets/video/5.mp4";
import collum6 from "../assets/video/6.mp4";
import collum7 from "../assets/video/7.mp4";
import collum8 from "../assets/video/8.mp4";
import collum9 from "../assets/video/9.mp4";
import collum10 from "../assets/video/10.mp4";
import collum11 from "../assets/video/11.mp4";
import collum12 from "../assets/video/12.mp4";
import collum13 from "../assets/video/13.mp4";
import collum14 from "../assets/video/14.mp4";
import collum15 from "../assets/video/15.mp4";
import collum16 from "../assets/video/16.mp4";
import collum17 from "../assets/video/17.mp4";
import collum18 from "../assets/video/18.mp4";
import collum19 from "../assets/video/19.mp4";
import collum20 from "../assets/video/20.mp4";
import collum21 from "../assets/video/21.mp4";
import collum22 from "../assets/video/22.mp4";
import useSound from "use-sound";
import ballFallAudio from "../assets/audio/Balls fall through Galton Board.mp3";
import winAudio_1 from "../assets/audio/Winner SFX - Option A.mp3";
import winAudio_2 from "../assets/audio/Winner SFX - Option B.mp3";
import winAudio_3 from "../assets/audio/Winner SFX - Option C.mp3";
import winAudio_4 from "../assets/audio/Winner SFX - Option D.mp3";
import loseAudio_1 from "../assets/audio/Lose SFX - Loser A.mp3";
import loseAudio_2 from "../assets/audio/Lose SFX - Loser B.mp3";
import loseAudio_3 from "../assets/audio/Lose SFX - Loser C.mp3";
import loseAudio_4 from "../assets/audio/Lose SFX - Loser D.mp3";

export default function VideoPlay(props) {
  console.log(props.land);
  let url;
  switch (props.land) {
    case 0:
      url = collum1;
      break;
    case 1:
      url = collum2;
      break;
    case 2:
      url = collum3;
      break;
    case 3:
      url = collum4;
      break;
    case 4:
      url = collum5;
      break;
    case 5:
      url = collum6;
      break;
    case 6:
      url = collum7;
      break;
    case 7:
      url = collum8;
      break;
    case 8:
      url = collum9;
      break;
    case 9:
      url = collum10;
      break;
    case 10:
      url = collum11;
      break;
    case 11:
      url = collum12;
      break;
    case 12:
      url = collum13;
      break;
    case 13:
      url = collum14;
      break;
    case 14:
      url = collum15;
      break;
    case 15:
      url = collum16;
      break;
    case 16:
      url = collum17;
      break;
    case 17:
      url = collum18;
      break;
    case 18:
      url = collum19;
      break;
    case 19:
      url = collum20;
      break;
    case 20:
      url = collum21;
      break;
    case 21:
      url = collum22;
      break;
    default:
      url = collum12;
      break;
  }

  console.log(url);

  const closeModal = () => {
    props.setOpen(false);
  };

  const [ballAudioPlay, { stop }] = useSound(ballFallAudio);
  const [winAudioPlay_1] = useSound(winAudio_1);
  const [winAudioPlay_2] = useSound(winAudio_2);
  const [winAudioPlay_3] = useSound(winAudio_3);
  const [winAudioPlay_4] = useSound(winAudio_4);
  const [loseAudioPlay_1] = useSound(loseAudio_1);
  const [loseAudioPlay_2] = useSound(loseAudio_2);
  const [loseAudioPlay_3] = useSound(loseAudio_3);
  const [loseAudioPlay_4] = useSound(loseAudio_4);

  const playWinAudio = () => {
    let rand = Math.floor(Math.random() * 4);
    switch (rand) {
      case 2:
        winAudioPlay_2();
        break;
      case 3:
        winAudioPlay_3();
        break;
      case 4:
        winAudioPlay_4();
        break;
      default:
        winAudioPlay_1();
    }
  };

  const playLoseAudio = () => {
    let rand = Math.floor(Math.random() * 4);
    switch (rand) {
      case 2:
        loseAudioPlay_2();
        break;
      case 3:
        loseAudioPlay_3();
        break;
      case 4:
        loseAudioPlay_4();
        break;
      default:
        loseAudioPlay_1();
    }
  };

  useEffect(() => {
    if (!props.isPlaying && props.land) {
      props.setRoundEnded(false);
      document.querySelector("video")?.play();
      ballAudioPlay();
      console.log("start playing");
      setTimeout(() => {
        videoEnded();
        if (props.reward > 0) {
          playWinAudio();
        } else {
          playLoseAudio();
        }
      }, 19000);
    }
  }, [props.land]);

  const videoEnded = () => {
    setTimeout(() => {
      props.setOpen(false);
    }, 2500);
    props.setRoundEnded(true);
    stop();
  };
  return (
    <>
      {props.isOpen && props.land ? (
        <section className="modal__bg">
          <div className="modal__align">
            <div className="modal__content">
              <IoCloseOutline
                className="modal__close"
                arial-label="Close modal"
                onClick={closeModal}
              />
              <div className="modal__video-align">
                <video width="600" height="600" controls>
                  <source src={url} type="video/mp4" />
                </video>
                <div className="final-txt">
                  {props.roundEnded
                    ? props.reward > 0
                      ? "Congratulations, you beat the curve! +" +
                        props.reward +
                        " SOL"
                      : "Try again next time"
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}
