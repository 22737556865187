import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Container, Navbar, Button } from "react-bootstrap";
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect } from "react";

function Nav(props) {
    const goToGameboard = () => {
        props.setBoardNumber(0)
    }
    const goToLeaderboard = () => {
        props.setBoardNumber(1)
    }
    
    return (
        <>
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                        alt=""
                        src="/wenlambo_dim.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top wenlambo-img"
                        />{' '}
                        
                    </Navbar.Brand>
                    <div className="move2">
                        <Button
                            variant={props.board === 0 ? "primary" : "outline-primary"}
                            onClick={goToGameboard}
                            className="m-4 px-5"
                        >
                            Gameboard
                        </Button>
                        <Button
                            variant={props.board === 1 ? "primary" : "outline-primary"}
                            onClick={goToLeaderboard}
                            className="m-4 px-5"
                        >
                            Payouts and History
                        </Button>
                    </div>
                    <WalletMultiButton />
                </Container>
            </Navbar>
        </>
    )
}

export default Nav;